<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/munavvarqori.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Мунавварқори Абдурашидхонов <br />(1878 -1931 )</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Мунавварқори Абдурашидхонов 1878 йилда Тошкентнинг Шайҳонтоҳур
            даҳасидаги Дархон маҳалласида диндор зиёли оиласида таваллуд топган.
            1904 йилдан бошлаб фаол ижтимоий ҳаётга кириб борди.«Шўрои Ислом»
            ташкилотининг (1917 — 18) идеологи ва раҳбари сифатида фаолият
            кўрсатди. 1929 йилда ноҳақ миллатчилиқда айбланиб қамоққа олинди ва
            1931 йилда отиб ташланди. Унинг ҳоки Москвадаги Ваганково
            қабристонидадир. Мунавварқори 1903 йилдан жадид мактаблари очиб,
            дарс берган. Шундай мактаблар учун товуш усулида ёзган «Адиби аввал»
            («Биринчи адиб», 1907) алифбе китоби, «Адиби соний» («Иккинчи адиб»,
            1907. Унда «Ҳимматли фақир» шеъри келтирилган) ўқиш китоблари бир
            неча бор нашр қилинган.1908 йил унинг «Сабзазор» (тўплам), «Ер юзи»
            (географиядан), «Тажвидал Қуръон» (Қуронни ўқиш усулини ўргатган)
            китоблари ҳам босилиб, янги усулдаги мактабларда дарслик сифатида
            қўлланган. 1906-1917 йилларда жадид газеталарида муҳаррирлик қилган,
            «Хуршид», «Нажот», «Кенгаш» каби газета ва бир қанча журналларга
            асос солган, жадидлар театри тарғиботчиси бўлган. Мунавварқори
            миллатимизнинг маърифий келгуси ва мустақиллик йўлини мунаввар қилиб
            кетган зотдир. «Чор ҳукуматини йўқотиш жадидларнинг тилагида бор
            эди» деб ёзган эди 20-йилдаги мақолаларидан бирида у. Туркистонда
            миллий матбуотга асос солди ва 1906 йилда «Хуршид» газетасини нашр
            этди. И.Обидий муҳаррирлигида нашр этилган «Тараққий» газетасини ҳар
            жиҳатдан бошқарди, «Жамияти имдодия» (Хайрия жамияти 1909)ни ташкил
            этди, «Садойи Туркистон» (1914), «Ал Ислоҳ» (1915), «Нажот»,
            «Кенгаш» (1917) газета-журналларига муҳаррирлик, раҳнамолик қилди,
            биринчилар қатори янги усулдаги мактабларни очди ва унинг
            программасини яратди. Бевосита унинг ташаббуси билан 1917 йилда
            миллий мактаб, ўқитувчилар тайёрлаш курслари ҳамда 1918 йилда унинг
            раҳбарлигида Туркистон дорулфунини очилди. Ўзбек тили дарслиги
            бевосита Мунаввар қори Абдурашидхонов раҳбарлигида яратилиб,
            халқимизга «Адиби аввал», «Адибус соний», «Ўзбекча тил сабоқлиги»
            тарзида тақдим этилди. Шунингдек, у болаларга бағишлаб ёзилган қатор
            шеър ва ҳикоялар муаллифи сифатида ўзбек болалар адабиётини бошлаб
            берган адибдир.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
